<template>
<!--  <div class="t-s-container">-->
<!--    <div class="page-outter">-->
<!--      <div align="center">-->
<!--        <img-->
<!--          class="t-s-title"-->
<!--          v-lazy="require('../../../assets/images/title2.png')"-->
<!--        />-->
<!--        <span class="t-s-desc">先进技术应用+贴心服务跟进</span>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="t-s-d1">-->
<!--      <img-->
<!--        width="100%"-->
<!--        v-lazy="require('../../../assets/images/bg-4.png')"-->
<!--      />-->
<!--      <div class="t-s-d2"></div>-->
<!--    </div>-->

<!--    <div class="t-s-d3">-->
<!--      <div class="page-outter">-->
<!--        <ul class="t-s-u1">-->
<!--          <li-->
<!--            v-for="(item,index) in tdata1"-->
<!--            :key="index"-->
<!--          >-->
<!--            <div align="center">-->
<!--              <div class="u1-item">-->
<!--                <img v-lazy="item.icon" />-->
<!--              </div>-->
<!--              <span class="u1-s1">{{item.name}}</span>-->
<!--            </div>-->
<!--          </li>-->
<!--        </ul>-->
<!--        <div style="clear: both"></div>-->
<!--      </div>-->
      <nav-footer></nav-footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "TechnicalServices",
  components: {
    "nav-footer": () => import("@/components/navFooter"),
  },
  data() {
    return {
      tdata1: [
        {
          icon: require("../../../assets/images/f-1.png"),
          name: "移动互联网技术",
        },
        {
          icon: require("../../../assets/images/f-2.png"),
          name: "区块链技术",
        },
        {
          icon: require("../../../assets/images/f-3.png"),
          name: "智能BI技术",
        },
        {
          icon: require("../../../assets/images/f-4.png"),
          name: "物联网应用",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.t-s-container {
  border-top: 1px solid #fff;
  background: #fff;
}
.t-s-title {
  display: block;
  width: 7rem;
}
.t-s-desc {
  display: block;
  font-size: 0.3rem;
  color: #aaaaaa;
  margin-top: 0.4rem;
}
.t-s-d1 {
  width: 100%;
  min-width: 660px;
  margin-top: 2rem;
  img {
    display: block;
  }
}
.t-s-d2 {
  width: 100%;
  height: 0.2rem;
  background: #6aa5ff;
}
.t-s-d3 {
  width: 100%;
  background: #4a4a4a;
  border-top: 1px solid #4a4a4a;
  border-bottom: 1px solid #4a4a4a;
  .t-s-u1 {
    list-style: none;
    margin: 0px;
    margin-top: 0.7rem;
    margin-left: -4.5rem;
    li {
      float: left;
      margin-left: 4.5rem;
      .u1-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.4rem;
        height: 2.4em;
        border-radius: 2.4rem;
        border: 1px solid #dcdadd;
        img {
          width: 1.8rem;
        }
      }
      .u1-s1 {
        display: block;
        font-size: 0.4rem;
        color: #dcdadd;
        margin-top: 0.6rem;
      }
    }
  }
}
</style>